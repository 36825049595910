import type { GetStaticProps, NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import React from 'react'

const WhatsAppButton = dynamic(() => import('src/components/WhatsappButton'))
const LandingPage = dynamic(() => import('src/screens/Landing/LandingPage'))
const LandingNav = dynamic(() => import('src/components/LandingNav'))
const Footer = dynamic(() => import('src/components/Footer'))
const GooglePopup = dynamic(() => import('src/components/Login/GooglePopup'), {
  ssr: false
})

const { titleOg, descriptionOg, title, description, image, url } = {
  titleOg: 'SpotMe | Marketplace de Almacenamiento - Fácil, Seguro, Económico',
  descriptionOg:
    'Ahorra un 30% en almacenamiento seguro y cercano. Renta tu espacio vacío y gana un ingreso extra.',
  title:
    'SpotMe | Bodegas en renta, renta de estacionamiento | Genera ingresos pasivos',
  description:
    'Marketplace de almacenamiento en Mexico. Ahorra hasta 30% en el alquiler de tu bodega o estacionamiento. Genera ingresos rentando tus espacios. Seguro y económico.',
  image:
    'https://www.spotme.mx/_next/image?url=%2Fimages%2Fcover.png&w=1200&q=100',
  url: 'https://www.spotme.mx'
}

const Page: NextPage = () => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="icon" href="/logos/Logo_Grande.ico" />
        <link rel="canonical" href={url} />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content={titleOg} />
        <meta property="og:type" content="website" />
        <meta property="og:locale:alternate" content="es_ES" />
        <meta property="og:locale:alternate" content="es_ES" />
        <meta property="og:url" content={url} />
        <meta property="fb:app_id" content="1664394843756674" />
        <meta property="og:image" content={image} itemProp="image" />
        <meta property="og:description" content={descriptionOg} />
        <meta
          name="google-site-verification"
          content="8_Ek6RgXQEQP_KAeF0k-7o8z66LFCJyfhVL6YuNNmlY"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@spotmemx" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Head>

      <main>
        <LandingNav />
        <LandingPage />
        <Footer />

        <WhatsAppButton />
        <GooglePopup />
      </main>
    </div>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const translations = await serverSideTranslations(locale ?? 'es', [
    'common',
    'landing',
    'footer',
    'nav'
  ])
  return {
    props: {
      ...translations
    }
  }
}

export default React.memo(Page)
